import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductHighlight from "../components/ProductHighlight";

const Content = () => {
  return (
    <Layout>
      <Seo title="Dr Pepper Addiction" description="Dr Pepper Addiction is a fan site dedicated to those who have a deep craving for the Dr Pepper Soda" />
      <Container>
        <Row>
          <Col>
            <h2 className="mb-5 text-center">Our Affiliate Disclosure</h2>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>
              Dr Pepper Addiction(DrPepperaAdiction.Com) is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn
              advertising fees by advertising and linking to Amazon.com
            </p>
            <p>Affiliate links do not impact your cost as a consumer in anyway. Your cost to purchase goods is the same regardless of our affiliate links.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Content;
